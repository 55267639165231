/* General Reset */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* App Container */
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #4e2a84, #000); /* Subtle gradient background */
  color: white;
}

/* Landing Container */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1; /* Take the full available space */
  background: linear-gradient(135deg, #4e2a84, #333); /* Subtle gradient */
}

/* Logo Styling */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 300px; /* Adjust size for your resized logo */
  height: auto;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5)); /* Softer shadow */
}

/* Welcome Text */
.welcome-text {
  text-align: center;
  margin-top: 20px;
}

.welcome-text h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffffff; /* White text for readability */
}

.welcome-text p {
  font-size: 1.2rem;
  color: #cccccc; /* Softer gray for subheading */
}

/* Banking App Container */
.banking-app-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take the full height available */
  background-color: #f0f0f0; /* Neutral background */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

/* Footer */
.footer {
  background-color: #4e2a84; /* Match with header background */
  color: white;
  text-align: center;
  padding: 10px 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Content Area */
.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  overflow-y: auto; /* Ensure scroll if content exceeds screen */
  border-top: 1px solid #dddddd; /* Subtle border for separation */
}

/* Header */
.header {
  background-color: #4e2a84; /* Match the footer with subtle purple */
  padding: 10px 0;
  color: white;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
